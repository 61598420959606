<template>
    <div>
        <Navbar page="Profissionais no Projeto" link="/cadastros" nameLink="Cadastros" video="https://www.youtube.com/watch?v=TtljzS_OSCE" pdf="/itens_exclusivos.pdf" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">            
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-2">
                        <span class="max-w-xl block text-sm">
                            Digite o <b>email</b> do profissional que gostaria de convidar para o projeto, 
                            se ele <b>já existir</b> na plataforma será enviado <b>convite</b> para participar deste projeto, 
                            se ele ainda <b>não tem conta</b> será criada a conta com o email fornecido e enviado link para <b>ativar</b> a mesma.
                        </span>

                        <div class="grid grid-cols-12 gap-6 mt-3">
                            <div class="col-span-12 md:col-span-4">
                                <label for="email" class="block text-sm font-medium">Email *</label>
                                <input v-model="form.email" type="email" name="email" id="name" autocomplete="off" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Convidar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            route: 'profissionais',
            form: {
                email: '',
            },
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}/convidar`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: '/cadastros/profissionais'});
            } else {
                if (req.data.perguntarSeQuerReativar) {
                    this.$confirm({
                        title: 'Esse profissional foi excluído',
                        message: `Deseja Reativar esse profissional no projeto?`,
                        button: {
                            no: 'Não',
                            yes: 'Sim',
                        },
                        callback: async confirm => {
                            if(!confirm) return;
                            await this.$http[method](`/v1/${this.route}/reativar`, this.form);
                            this.$vToastify.success("Profissional reativado com sucesso!");
                            this.$router.push({path: '/cadastros/profissionais'});
                        }
                    });
                } else {
                    this.$vToastify.error(req.data.err);
                }
            }
        },
    },
}
</script>